import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';

import {
  Button,
  ColumnLayout,
  Icon,
  LoadingSpinner,
  Page,
  SubPage,
  Title,
  USE_WEB_WORKER,
  getWebWorkerOption,
  useAsyncComponent,
  useLocation,
} from '@adac/core-view';

import {
  __,
  isValidMobilePhoneNumber,
  saveToLocalStorage,
} from '@adac/core-model';
import styled from 'styled-components';
import { useWorkflowCallCustomerAction } from '../../hooks/useWorkflowCallCustomerAction';
import StoreContext from '../../stores';
import { routeNames } from '../layout/OverlayRouterContainer';
import Door from './Door';

const CallButton = styled(Button)`
  &,
  & > a {
    flex-direction: row-reverse;
    gap: 3px;
  }
`;

const ComissionOpeningForm = observer(() => {
  const doorStore = useContext(StoreContext).beforeOpeningDoorPhotos;
  const { goTo } = useLocation();
  const { case: caseStore } = React.useContext(StoreContext);

  const callCustomerAction = useWorkflowCallCustomerAction();

  const { Component: CallCustomerButton } = useAsyncComponent({
    Component: CallButton,
    onClick: () =>
      callCustomerAction({
        companyId: caseStore.currentCompanyId,
        driverId: caseStore.driverInfo?.driver?.id,
        number: caseStore.customerPhone,
      }),
    enableAfterLoading: true,
  });

  const isPhotoLoading = doorStore.isLoading;

  const { render: WebWorkerToggle, setFeatureFlagCount } =
    useWebWorkerFeatureFlag();

  return (
    <Page>
      <SubPage>
        {isValidMobilePhoneNumber(caseStore.customerPhone) && (
          <CallCustomerButton
            link={`tel: ${caseStore.customerPhone}`}
            info
            title={__('Make a customer call')}
            margin
          >
            <Icon name='phone' />
          </CallCustomerButton>
        )}

        <WebWorkerToggle />
        <Title onClick={() => setFeatureFlagCount((prev) => prev + 1)}>
          {__('previousDamageDetection')}
        </Title>

        {isPhotoLoading ? (
          <LoadingSpinner>{__('Photos are loading')}</LoadingSpinner>
        ) : (
          <Door doorStore={doorStore} />
        )}

        <Button
          cta
          disabled={
            doorStore.photosUploadedByUser.length === 0 || isPhotoLoading
          }
          title='Weiter'
          onClick={() => goTo(routeNames.commissionReport('confirm'))}
        />
      </SubPage>
    </Page>
  );
});

export default ComissionOpeningForm;

function useWebWorkerFeatureFlag() {
  const [featureFlagCount, setFeatureFlagCount] = useState(0);
  const [isWebWorkerEnabled, setIsWebWorkerEnabled] =
    useState(getWebWorkerOption());

  const toggleWebWorker = () => {
    const newWebWorkerState = !isWebWorkerEnabled;

    saveToLocalStorage(USE_WEB_WORKER, String(newWebWorkerState));
    setIsWebWorkerEnabled(newWebWorkerState);
  };

  return {
    setFeatureFlagCount,
    render: () => {
      if (featureFlagCount >= 5) {
        return (
          <ColumnLayout
            ratio='1fr 1fr'
            marginTop='16px'
            marginBottom='16px'
            gap='8px'
          >
            <Button
              onClick={toggleWebWorker}
              cta
              title={`Use webworker ${String(isWebWorkerEnabled)}`}
            />
            <Button onClick={() => setFeatureFlagCount(0)} info title='Hide' />
          </ColumnLayout>
        );
      }
      return <></>;
    },
  };
}

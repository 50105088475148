import React, { useState, useContext, useRef, ChangeEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import {
  Title,
  Page,
  SubPage,
  Text,
  SubTitle,
  TextField,
  Select,
  NetworkStoreLoader,
  LoadingSpinner,
  useLocation,
  Timer,
  BackButton,
  ButtonListStyles,
  GetAllStore,
  ErrorMessage,
  useAsyncComponent,
  getDriversToDispatch,
  PostStore,
  sndAcceptOffer,
  Driver,
  CaseTokenParams,
  ChildrenProps,
} from '@adac/core-view';
import { __, CaseStatusList, CipheredToken } from '@adac/core-model';

import StoresContext from '../../stores';

const SubContent = styled(SubPage)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const LargeText = styled(Text)`
  font-size: ${(props) => props.theme.font.subTitle};
  margin-top: 8px;
`;

const ButtonContainer = styled(ButtonListStyles)`
  margin-top: 24px;
`;

interface DispatchToDriverProps
  extends RouteComponentProps<{ companyId?: string }>,
    ChildrenProps {}

const DispatchToDriver = observer(
  ({ match, children }: DispatchToDriverProps) => {
    const { goTo } = useLocation();
    const {
      ui: uiStore,
      case: {
        token: caseToken,
        accessToken,
        currentCompanyId,
        hasError,
        getDispatchDriverTimeLimit,
        handleStoreAction,
      },
    } = useContext(StoresContext);
    const getCompanyDriverStoreContainer = useRef(
      new GetAllStore<Driver, CaseTokenParams<CipheredToken>>(
        getDriversToDispatch
      )
    );
    const companyDriversStore = getCompanyDriverStoreContainer.current;
    const [arrivalTime, setArrivalTime] = useState('');

    const companyId =
      parseInt(match.params?.companyId ?? '', 10) || currentCompanyId;
    const estimation = parseInt(arrivalTime, 10);

    const firstDirverId =
      (companyDriversStore &&
        companyDriversStore.items &&
        companyDriversStore.items.length > 0 &&
        companyDriversStore.items[0].id) ||
      0;

    const [selectedDriver, selectDriver] = useState(firstDirverId);

    const sendingDriverId = selectedDriver || firstDirverId;

    const onButtonClick = async () => {
      if (sendingDriverId <= 0) {
        uiStore.setOverlay(
          <ErrorMessage>{__('Driver was not set')}</ErrorMessage>
        );
      } else {
        if (!companyId) throw new Error(`companyID is missing: ${companyId}`);
        if (!estimation)
          throw new Error(`estimation is missing: ${estimation}`);

        const sndAcceptOfferStore = new PostStore(sndAcceptOffer);
        const item = await sndAcceptOfferStore.postItem(
          { caseToken, accessToken },
          {
            companyId,
            driverId: sendingDriverId,
            estimation,
          }
        );
        const status = await handleStoreAction(sndAcceptOfferStore, item);

        if (!hasError) {
          // shall redirect to main page, as we expected to receive the job, on SWA process
          if (
            `${currentCompanyId}` === `${companyId}` &&
            [
              `${CaseStatusList.DRIVER_SELECTED}`,
              `${CaseStatusList.SND_SELECTED}`,
            ].includes(status)
          )
            goTo('/');
        }
      }
    };

    const getErrorMessage = () =>
      (companyDriversStore.lastErrorMessage &&
        (companyDriversStore.lastErrorMessage.toLocalizedString
          ? companyDriversStore.lastErrorMessage.toLocalizedString()
          : companyDriversStore.lastErrorMessage.toString())) ||
      'Error';

    const {
      Component: SendEstimationButton,
      setError,
      setHasLoaded,
    } = useAsyncComponent({
      disabled: !arrivalTime,
      onClick: onButtonClick,
      enableAfterLoading: true,
    });

    const onArrivalTimeChange = React.useCallback(
      (v: string) => {
        setError(null);
        setHasLoaded(false);
        setArrivalTime(v);
      },
      [setError, setHasLoaded]
    );

    return (
      <Page>
        <SubContent>
          <Title>{__('Timesuggestion Quesion1')}</Title>
          <LargeText>{__('RemainingTime')}</LargeText>
          <Timer
            startTime={getDispatchDriverTimeLimit}
            countdown
            onCountdown={<ErrorMessage>{__('TimeOver')}</ErrorMessage>}
          />
          <NetworkStoreLoader
            store={companyDriversStore}
            action={() =>
              companyDriversStore.getAllItems({ caseToken, accessToken })
            }
            loadingView={
              <LoadingSpinner>
                {__('Loading drivers for your company')}...
              </LoadingSpinner>
            }
            errorView={
              <ErrorMessage>
                {getErrorMessage()} {__('on fetching drivers for the company')}
              </ErrorMessage>
            }
          >
            <Select
              id='name'
              name='name'
              options={companyDriversStore.items.map((driver) => ({
                ...driver,
                name: `${driver.firstName} ${driver.familyName}`,
              }))}
              value={selectedDriver}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                selectDriver(Number(e.target.value))
              }
            />
          </NetworkStoreLoader>

          <SubTitle>{__('Timesuggestion Title')}</SubTitle>

          <LargeText margin>{__('Timesuggestion Guidance')}</LargeText>

          {children}

          <TextField
            name='arrivalTime'
            value={arrivalTime}
            badgeEqualsPlaceholder
            badgeTitle='Anfahrtszeit (in Minuten)'
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onArrivalTimeChange(e.target.value)
            }
          />
          <ButtonContainer>
            <BackButton info title={__('Back')} />
            <SendEstimationButton type='submit' cta title={__('Send')} />
          </ButtonContainer>
        </SubContent>
      </Page>
    );
  }
);

export default DispatchToDriver;

import React from 'react';
import { BasePriceTypes, config } from '@adac/core-model';
import { observer } from 'mobx-react';
import { OfferPriceView, Text, getOfferText } from '@adac/core-view';
import { useIsInsuranceProduct } from '../../hooks/useIsInsuranceProduct';

interface PriceDetailsProps {
  baseAmountType?: BasePriceTypes;
  companySelectedDate?: Date;
  children?: React.ReactNode;
}
export const PriceDetails = observer(
  ({ baseAmountType, companySelectedDate, children }: PriceDetailsProps) => {
    const isInsurance = useIsInsuranceProduct();
    return (
      <>
        {!isInsurance && <OfferPriceView type={baseAmountType} />}
        <Text margin light>
          {getOfferText(
            companySelectedDate,
            config.date(companySelectedDate, 'dddd, ')
          )}
        </Text>
        {children}
      </>
    );
  }
);
